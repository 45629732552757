import React, { useState } from "react";
import logo from "../assets/phoenix_logo.png";
// import SEO from "../SEO";

const TrademarkRenewalChecklist = () => {
  const [showChecklist, setShowChecklist] = useState(false);

  // Checklist items focusing on "what" is needed for renewal
  const checklistItems = [
    "Trademark  Number",
    "Owner's Information",
    "Address",
    "Trademark Evidence of Use",
    "Statement of Use",
    "Proof of Payment",
    "Class of Goods or Services",
    "Any Amendments",
    "Verification of Renewal",
  ];

  // Toggle checklist visibility
  const handleShowChecklist = () => {
    setShowChecklist(!showChecklist);
  };

  return (
    <div style={styles.page}>
      {/* <SEO
        title="Checklist for Trademark Renewal | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header style={styles.header}>
        <div className={logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <h1>Trademark Renewal Made Simple</h1>
        <p>
          Keep your trademark protected. Use our checklist to ensure you have
          everything ready for renewal.
        </p>
        <button style={styles.button} onClick={handleShowChecklist}>
          {showChecklist
            ? "Hide Checklist"
            : "Review Our Free Trademark Renewal Checklist"}
        </button>
      </header>

      {showChecklist && (
        <section style={styles.checklist}>
          <h2>Checklist for Trademark Renewal</h2>
          <ul style={styles.list}>
            {checklistItems.map((item, index) => (
              <li key={index} style={styles.listItem}>
                {item}
              </li>
            ))}
          </ul>
        </section>
      )}

      <footer style={styles.footer}>
        <p>
          Need help?{" "}
          <a href="mailto:contact@ziprenewal.com">
            Contact our team (contact@ziprenewal.com)
          </a>{" "}
          for assistance with your trademark renewal.
        </p>
      </footer>
    </div>
  );
};

// Inline styles for simplicity
const styles = {
  page: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    color: "#333",
    maxWidth: "800px",
    margin: "auto",
    textAlign: "center",
  },
  header: {
    marginBottom: "30px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s",
  },
  checklist: {
    textAlign: "left",
    marginTop: "20px",
  },
  list: {
    listStyleType: "circle",
    paddingLeft: "20px",
  },
  listItem: {
    margin: "10px 0",
    fontSize: "18px",
  },
  footer: {
    marginTop: "40px",
    fontSize: "14px",
    color: "#666",
  },
};

export default TrademarkRenewalChecklist;
