import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true,
  debug: false,
};

// Define standard Facebook events
const standardEvents = [
  "PageView",
  "ViewContent",
  "Search",
  "AddToCart",
  "Purchase",
  // add other standard events here  see --> https://www.facebook.com/business/help/402791146561655?id=1205376682832142
];

export const initFacebookPixel = () => {
  // console.log("Pixel initialized");
  // console.log(process.env.REACT_APP_FACEBOOK_TRACKING);
  if (process.env.REACT_APP_FACEBOOK_TRACKING === "True") {
    ReactPixel.init("1579729142643673", null, options);
    // ReactPixel.pageView();
  }
};

export const trackEvent = (event, data) => {
  if (process.env.REACT_APP_FACEBOOK_TRACKING === "True") {
    if (standardEvents.includes(event)) {
      // standard event track method
      ReactPixel.track(event, data);
    } else {
      // non-standard events track method
      ReactPixel.trackCustom(event, data);
    }
  }
};
