import React, { useState } from "react";
import logo from "../assets/square_logo.png";
// import SEO from "../SEO";

const ZipRenewalProcess = () => {
  const [showProcess, setShowProcess] = useState(false);

  // ZipRenewal process outlining the steps taken for clients
  const processSteps = [
    "We handle all the paperwork for your trademark renewal.",
    "Our team ensures an error-free submission by double-checking all details.",
    "You simply provide the necessary information, and we take care of the rest.",
    "We ensure compliance with trademark office requirements, avoiding delays or rejections.",
    "Receive real-time updates on your renewal status via our dashboard.",
    "Your trademark remains protected, and you'll have peace of mind knowing it's renewed on time.",
  ];

  return (
    <div style={styles.page}>
      {/* <SEO
        title="Trademark Renewal Process | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header>
        <div className={logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <h1>Our Trademark Renewal Process</h1>
        <p>
          At ZipRenewal, we take the hassle out of renewing your trademark.
          Here's how it works:
        </p>
        <button
          style={styles.button}
          onClick={() => setShowProcess(!showProcess)}
        >
          {showProcess ? "Hide Process" : "See How We Handle Your Renewal"}
        </button>
      </header>

      {showProcess && (
        <section style={styles.process}>
          <h2>What We Do For You</h2>
          <ul>
            {processSteps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};

// Inline styles for simplicity
const styles = {
  page: { textAlign: "center", padding: "20px", fontFamily: "Arial" },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    marginTop: "20px",
  },
  process: {
    marginTop: "20px",
    textAlign: "left",
    maxWidth: "600px",
    margin: "0 auto",
  },
};

export default ZipRenewalProcess;
