import React from "react";
import logo from "../assets/circle_logo.png";
// import SEO from "../SEO";

const RenewalSummary = () => {
  return (
    <div style={styles.container}>
      {/* <SEO
        title="Need help with your trademark renewal | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header style={styles.header}>
        <div className={logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <h1>The Importance of Renewing Your Trademark</h1>
        <p>
          Ensuring your trademark remains active and protected is critical for
          maintaining ownership of your brand and its legal protections.
        </p>
      </header>

      <section style={styles.section}>
        <h2>Why Trademarks Matter</h2>
        <p>
          A trademark protects your brand’s identity. It grants you
          national-level ownership and the right to use your brand exclusively.
          Once registered, the trademark allows you to prevent others from using
          a confusingly similar mark. It also gives you the ability to enforce
          your rights in federal court.
        </p>
      </section>

      <section style={styles.section}>
        <h2>Making Your Trademark Renewal Error-Free</h2>
        <p>
          At ZipRenewal, we handle all the paperwork and ensure the renewal
          process is smooth, so you don’t have to worry about losing your
          trademark due to errors or missed deadlines. By renewing your
          trademark, you retain the right to use the ® symbol and continue
          protecting your business and reputation.
        </p>
      </section>

      <section style={styles.section}>
        <h2>What to Consider During Renewal</h2>
        <ul style={styles.list}>
          <li>Ensure the trademark is still in use.</li>
          <li>Verify the correct information is submitted.</li>
          <li>Provide proof of continued use of your trademark.</li>
          <li>Pay any applicable renewal fees to the USPTO.</li>
        </ul>
      </section>

      <section style={styles.section}>
        <h2>Why Choose ZipRenewal?</h2>
        <p>
          We ensure an error-free, simple, and timely renewal process, so you
          can focus on growing your business without the risk of losing your
          trademark protection. Our expertise reduces the likelihood of costly
          mistakes and ensures compliance with all trademark office
          requirements.
        </p>
      </section>

      <footer style={styles.footer}>
        <p>
          Need help with your trademark renewal?{" "}
          <a href="mailto:contact@ziprenewal.com">Contact us today</a>.
        </p>
      </footer>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "auto",
    backgroundColor: "#f9f9f9",
    color: "#333",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  section: {
    marginBottom: "20px",
  },
  list: {
    paddingLeft: "20px",
    textAlign: "left",
  },
  footer: {
    textAlign: "center",
    marginTop: "40px",
  },
};

export default RenewalSummary;
