import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <h1>Privacy Policy</h1>
      <p>
        By clicking "Submit," you agree to share your contact information with
        ZipRenewal. We value your privacy and are committed to protecting your
        personal information. This information will be used solely for the
        purpose of providing you with information about our trademark
        registration services, answering your questions, and processing your
        request. We will not sell, share, or distribute your information to
        third parties except as necessary to fulfill our services or as required
        by law.
      </p>

      <h2>Data Collection and Use</h2>
      <p>
        We collect only the information you choose to provide, such as your
        name, email address, and any additional details relevant to your
        inquiry. This data helps us deliver personalized assistance and stay in
        touch regarding updates, promotions, or relevant services.
      </p>

      <h2>Data Storage and Security</h2>
      <p>
        Your information is stored securely, and we implement strict measures to
        protect it against unauthorized access, disclosure, or misuse. Access is
        limited to authorized personnel only, and we use secure systems to
        safeguard your data.
      </p>

      <h2>Your Rights</h2>
      <p>
        You may request access to, correction of, or deletion of your personal
        information at any time. If you have questions regarding how your
        information is used, please reach out to us at{" "}
        <a href="mailto:contact@ziprenewal.com">contact@ziprenewal.com</a>.
      </p>

      <h2>Facebook Data Policy</h2>
      <p>
        Facebook may also use the data you submit, subject to Facebook’s{" "}
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Policy
        </a>
        , which includes auto-filling forms for your convenience.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
