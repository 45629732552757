import React, { useState, useEffect } from "react";
import style_a from "../styles/ziprenewal_a.module.css";
import axios from "axios";
import heroImage from "../assets/shutterstock_2475466195.jpg";
import logo from "../assets/phoenix_logo2.png";
import { initFacebookPixel, trackEvent } from "../utils/facebook_pixel";
import { useLocation } from "react-router-dom";
// import SEO from "../SEO";

const ZipRenewal_a = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");

  const location = useLocation();

  const ad = "cost-effective-renewal";
  const campaign = "ZipRenewal Discovery Campaign";

  useEffect(() => {
    initFacebookPixel();
  }, []);
  useEffect(() => {
    trackEvent("PageView", {
      page: location.pathname,
      ad_id: ad,
      campaign_id: campaign,
    });

    // trackEvent("PageViewA", {
    //   page: location.pathname,
    //   ad_id: ad,
    //   campaign_id: campaign,
    // });
  }, [location.pathname]);

  const handleClick = async () => {
    if (!email) {
      trackEvent("ChecklistButtonClick", {
        content_name: "Download Checklist Button",
        ad_id: ad,
        campaign_id: campaign,
      });

      setShowModal(true);
      return;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      setEmail("");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailApi/email`,
        {
          email,
          inputValue: "",
          actionType: "renewal-checklist",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setEmail("");
        window.location.href = "/checklist"; // Redirect to '/checklist' on click
      }
    } catch (error) {
      console.error("Error storing email:", error);
    }
  };

  const handleEmailSubmit = () => {
    trackEvent("ChecklistEmailButtonClick", {
      content_name: "Provided Email Button",
      ad_id: ad,
      campaign_id: campaign,
    });

    setShowModal(false);
    handleClick();
  };

  return (
    <>
      {/* <SEO
        title="Protect Your Brand with Confidence | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header className={style_a.header}>
        <div className={style_a.logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <nav className={style_a.navMenu} style={{ display: "none" }}>
          <a href="/home" className={style_a.navLink}>
            Home
          </a>
          <a href="/how-it-works" className={style_a.navLink}>
            How It Works
          </a>
          <a href="/pricing" className={style_a.navLink}>
            Pricing
          </a>
          <a href="/testimonials" className={style_a.navLink}>
            Testimonials
          </a>
          <a href="/contact" className={style_a.navLink}>
            Contact
          </a>
          <button className={style_a.ctaButton}>
            Protect My Trademark Today
          </button>
        </nav>
      </header>

      <section className={style_a.hero}>
        <div className={style_a.heroContent}>
          {/* <h1>Protect Your Brand with Confidence—No Legal Expertise Needed</h1> */}
          <h1>Protect Your Brand with Confidence</h1>
          {/* <h2>
            Simple, Affordable Trademark Renewal and Monitoring for
            Entrepreneurs and Small Business Owners
          </h2> */}
          <h2>Trademark Renewal for Entrepreneurs and Small Business Owners</h2>
          <p>
            Stay focused on growing your business—ZipRenewal makes trademark
            protection easy.
          </p>

          <button className={style_a.primaryCta} onClick={handleClick}>
            Download Your Free Trademark Renewal Checklist
          </button>
        </div>
        <div className={style_a.heroImage}>
          <img src={heroImage} alt="Business owner working" />
        </div>
      </section>

      <section className={style_a.valueProposition}>
        <div className={style_a.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/documents-1699092-1449731.png?f=webp&w=256"
            alt="Document Icon"
          />
          <p>
            Affordable, Simple Trademark Renewal: Streamline the renewal process
            in just a few steps.
          </p>
        </div>
        <div className={style_a.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/shield-6403655-5388095.png?f=webp&w=256"
            alt="Shield Icon"
          />
          <p>
            Peace of Mind Monitoring: Stay compliant and informed with proactive
            updates.
          </p>
        </div>
        <div className={style_a.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/dollar-1111-891546.png?f=webp&w=256"
            alt="Dollar Icon"
          />
          <p>
            Confidence in Brand Protection: Safeguard your brand without the
            high costs of law firms.
          </p>
        </div>
      </section>

      <section className={style_a.testimonial}>
        <p>
          "ZipRenewal made renewing my trademark simple and stress-free. I
          didn’t need a lawyer, and my brand is protected!"
        </p>
        <div className={style_a.trustLogos} style={{ display: "none" }}>
          <img src="publication-logo1.png" alt="Publication 1" />
          <img src="publication-logo2.png" alt="Publication 2" />
          <img src="publication-logo3.png" alt="Publication 3" />
        </div>
      </section>

      {/* Modal for Email Input */}
      {showModal && (
        <div className={style_a.modal}>
          <div className={style_a.modalContent}>
            {/* Close button */}
            <button
              className={style_a.closeButton}
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <h2>Please Enter your email</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEmailSubmit();
                }
              }}
            />
            <button
              className={style_a.submitButton}
              onClick={handleEmailSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <footer className={style_a.footer}>
        <p>Your brand’s protection should be simple and affordable.</p>

        <a href="/simplify" className={style_a.footerLink}>
          How ZipRenewal Simplifies Trademark Protection
        </a>
      </footer>
    </>
  );
};

export default ZipRenewal_a;
