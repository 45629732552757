import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import ZipRenewalA from "./components/ziprenewal_a";
import ZipRenewalB from "./components/ziprenewal_b";
import ZipRenewalC from "./components/ziprenewal_c";
import Home from "./components/home";
import Trademark from "./components/trademark";
import TrademarkRenewalChecklist from "./components/tm_checklist";
import ZipRenewalProcess from "./components/process";
import RenewalSummary from "./components/guide";
import Policy from "./components/policy";

function App() {
  return (
    <Router>
      <section className="ziprenewal">
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Trademark />} />
            <Route path="/home" element={<Home />} />
            <Route path="/cost-effective-renewal" element={<ZipRenewalA />} />
            <Route path="/checklist" element={<TrademarkRenewalChecklist />} />
            <Route path="/hassle-free" element={<ZipRenewalB />} />
            <Route path="/process" element={<ZipRenewalProcess />} />
            <Route path="/brand-awareness" element={<ZipRenewalC />} />
            <Route path="/guide" element={<RenewalSummary />} />
            <Route path="/policy" element={<Policy />} />
          </Routes>
        </div>
      </section>
    </Router>
  );
}

export default App;
