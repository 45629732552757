import React, { useState, useEffect } from "react";
import axios from "axios";
import tm_style from "../styles/trademark.module.css";
import process from "../assets/process.png";
import secure from "../assets/secure.png";
import support from "../assets/support.png";
import logo from "../assets/New_Logo.png";

import statusCodes from "../statusCodes.json"; // Trademark status codes
import { initFacebookPixel, trackEvent } from "../utils/facebook_pixel";
import { useLocation } from "react-router-dom";

const IndexPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [email, setEmail] = useState(""); // State for email
  const [registrationDate, setRegistrationDate] = useState("");
  const [tm5Status, setTm5Status] = useState("");
  const [tm5StatusDesc, setTm5StatusDesc] = useState("");
  const [tm5StatusDef, setTm5StatusDef] = useState("");
  const [statusText, setStatusText] = useState("");
  const [initialRenewal, setInitialRenewal] = useState("");
  const [subsequentRenewal, setSubsequentRenewal] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);

  const activeStatusCodes = statusCodes.activeStatusCodes;
  const notActiveStatusCodes = statusCodes.notActiveStatusCodes;

  const location = useLocation();

  const ad = "TM Search";
  const campaign = "Age Restricted";

  useEffect(() => {
    initFacebookPixel();
  }, []);
  useEffect(() => {
    trackEvent("PageView", {
      page: location.pathname,
      ad_id: ad,
      campaign_id: campaign,
    });
  }, [location.pathname]);

  const resourceClick = async () => {
    trackEvent("ResourceButtonClick", {
      content_name: "Resource Button",
      ad_id: ad,
      campaign_id: campaign,
    });
  };

  const guideClick = async () => {
    trackEvent("GuideButtonClick", {
      content_name: "Guide Button",
      ad_id: ad,
      campaign_id: campaign,
    });
  };

  const handleSearch = async () => {
    // const trimmedSerialNumber = serialNumber.trim();

    if (!searchValue || !email) {
      alert("Please enter a trademark and email.");
      return;
    }

    trackEvent("SearchButtonClick", {
      content_name: "Submit Button",
      search_term: searchValue,
      ad_id: ad,
      campaign_id: campaign,
    });

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      setEmail("");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailApi/email`,
        {
          email,
          inputValue: searchValue,
          actionType: "tm-search-age-restriction",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setEmail("");
        setSearchValue("");
        setShowCard(true);
      }
    } catch (error) {
      console.error("Error storing email:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <header className={tm_style.header}>
        <div className={tm_style.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <p className={tm_style.tagline}>Trademark One-Touch Solution</p>
      </header>
      <section className={tm_style.hero}>
        {/* Conditionally render the CTA based on showCard state */}
        {!showCard && (
          <div className={tm_style.CTA}>
            <h2>Is Your Trademark Still Protected?</h2>

            <p className={tm_style.inputCTA}>
              Or has your brand protection already expired?
            </p>

            <p>
              Once it’s gone, it’s gone—don’t risk losing it forever. Many
              people don’t realize that letting a trademark expire could mean
              losing it for good.
            </p>
            <br />
            {/* <p className={tm_style.subText}>
              Simply enter your details, and we’ll send a full report on your
              trademark’s protection status. It’s that easy!

              
            </p> */}
            <p className={tm_style.subText}>
              Simply enter your details, and we’ll provide a comprehensive
              report detailing your trademark’s current protection status
            </p>

            <div className={tm_style.searchBar}>
              <input
                id="search"
                type="text"
                placeholder="Trademark or Company Name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                autoComplete="on"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
              <button onClick={handleSearch}>Free Report</button>
              {loading && <div className={tm_style.spinner}></div>}
            </div>
          </div>
        )}

        {/* Thank You Card */}
        {showCard && (
          <div className={tm_style.thankyouCard}>
            {/* Hero Banner */}
            <div className={tm_style.heroBanner}>
              <h2>Success! Your Brand’s Protection Just Got Easier.</h2>
              <p>
                Thank you for taking the first step towards safeguarding your
                trademark!
              </p>
            </div>

            {/* Next Steps */}
            <div className={tm_style.nextSteps}>
              <h3>What to Expect Next:</h3>
              <ul>
                <li>
                  <strong>Check Your Email:</strong> Look for an email from
                  ZipRenewal with your personalized trademark report.
                </li>
                <li>
                  <strong>Review Your Report:</strong> We’ll highlight your
                  current trademark status and next steps for protection.
                </li>
                <li>
                  <strong>Set Up Reminders:</strong> Stay ahead with renewal
                  alerts tailored to your trademark deadlines.
                </li>
              </ul>
            </div>

            {/* Additional CTA */}
            <div className={tm_style.additionalCTA}>
              <p>
                Ready to take the next step? Discover more ways to protect your
                brand:
              </p>
              <button
                className={tm_style.ctaButton}
                // onClick={() => (window.location.href = "/resources")}
              >
                View Our Trademark Resources
              </button>
            </div>

            {/* Testimonials */}
            <div className={tm_style.testimonials}>
              <h3>What Our Customers Say:</h3>
              <blockquote>
                "ZipRenewal knows more about renewing my trademark than anyone I
                know!"
              </blockquote>
              <blockquote>
                "The reminders and guides helped me better understand everything
                I needed to do to keep my trademark safe."
              </blockquote>
            </div>

            {/* Downloadable Resource */}
            <div className={tm_style.resourceDownload}>
              <p>
                Need more help? Download our FREE Trademark Renewal Guide for
                step-by-step assistance.
              </p>
              <button
                className={tm_style.ctaButton}
                // onClick={() => (window.location.href = "/free-guide")}
              >
                Download Guide
              </button>
            </div>

            {/* Social Sharing */}
            {/* <div className={tm_style.socialShare}>
              <p>Spread the word to help others protect their brands:</p>
              <div className={tm_style.socialIcons}>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../assets/social_media/facebook.png")}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../assets/social_media/twitter.png")}
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("../assets/social_media/linkedin.png")}
                    alt="LinkedIn"
                  />
                </a>
              </div>
            </div> */}
          </div>
        )}

        {/* <div className={tm_style.heroImage}>
          <img src={a_fold} alt="Promotional" />
        </div> */}
      </section>
      <footer className={tm_style.footer}>
        <p>&copy; 2024 ZipRenewal. All rights reserved.</p>
        <br />
        <p>
          ZipRenewal is trusted by business owners nationwide for stress-free
          trademark protection.
        </p>
      </footer>
    </div>
  );
};

export default IndexPage;
